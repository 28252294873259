import { lazy } from "react";

const Home = lazy(() => import("../pages/home"));
const LatestSection = lazy(() => import("../pages/Sections/Latest"));
const CategoriesPage = lazy(() => import("../pages/categoriesProducts"));
const ProfilePage = lazy(() => import("../pages/profile"));
const TurahSection = lazy(() => import("../pages/Sections/turah"));
const WishListSection = lazy(() => import("../pages/Sections/WishList"));
const PrivacyPolicy = lazy(() => import("../pages/privacy"));
const CommonQuestions = lazy(() => import("../pages/commonQuestions"));
const ReturnPolicy = lazy(() => import("../pages/ReturnPolicy"));
const BestSellerPage = lazy(() => import("../pages/home/BestSeller"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const Header = lazy(() => import("../layout/header"));
const EmailPopup = lazy(() => import("../components/EmailPopup"));
const Advertisment = lazy(() => import("../components/Advertisment"));
const Footer = lazy(() => import("../layout/footer"));
const MainRouter = lazy(() => import("./subRouters/Main"));
const AuthRouter = lazy(() => import("./subRouters/Auth"));

const CartUI = lazy(() =>
  import("../layout/header/Nav/MobileNav/MobileDrawer/MobileDrawer/CartUI")
);
const CompleteOrder = lazy(() => import("../pages/CompleteOrder"));
const Logout = lazy(() => import("../pages/Logout"));
const OrderFail = lazy(() => import("../pages/OrderCompletion/fail"));
const OrderSuccess = lazy(() => import("../pages/OrderCompletion/success"));
const ProductView = lazy(() => import("../pages/Products/View"));

export {
  Home,
  CategoriesPage,
  ProfilePage,
  LatestSection,
  TurahSection,
  WishListSection,
  PrivacyPolicy,
  CommonQuestions,
  ReturnPolicy,
  BestSellerPage,
  ResetPassword,
  Header,
  EmailPopup,
  Advertisment,
  Footer,
  MainRouter,
  AuthRouter,
  CartUI,
  CompleteOrder,
  Logout,
  OrderFail,
  OrderSuccess,
  ProductView,
};
