import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";
import { useTra } from "react-ga";
import { dispatchGoogleEvent } from "../../utils/google-analytics";

export default function PageViewTracker() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname]);

  // const hadnleClick = () => {
  //   dispatchGoogleEvent("test Event", { date: Date.now() });
  // };
  return null;
}
