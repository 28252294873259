import { ADD_TO_CART, REMOVE_FROM_CART, SET_CART_DATA, SET_ITEMS_LENGTH } from "../../actions/cart";
import { addElementToArray, remvoeElementFromArray } from "../../methods/cart";
import { initSharedState } from "./initialState";
  
  const cartReducer = (state = initSharedState, action) => {
  
    switch (action.type) {
        case ADD_TO_CART:{
            const {count , item} = action
            const newItems =  addElementToArray(item,state.items,count)
            return{
                ...state,
                items : newItems
            }
        }
        case REMOVE_FROM_CART:{
            const {all , item} = action
            const newItems =  remvoeElementFromArray(item,state.items,all)
              return{
                ...state,
                items : newItems
              }
        }
        case SET_CART_DATA:
          return{
            ...state,
            cartData : action.cartData
          }
        case SET_ITEMS_LENGTH:{
          return {
            ...state,
            items : action.items
          }
        }
      default:
        return {
          ...state,
        };
    }
  };
  
  export default cartReducer;
  