import en from  '../../translate/en.json'
import ar from  '../../translate/ar.json'
import { getLangauage, isAr } from './getLang';


export const translate = (id)=>{
    const srcLang = isAr() ? ar : en
    let value = id
    try {
        value = srcLang[id]
        if(!value)
        return id
    } catch (error) {
        return value
    }
    return value
    
}