import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './index.scss';
import 'antd/dist/antd.css'; 
import './app/utils/sentry/setup'
import './app/utils/google-analytics/setup'
import reportWebVitals from './reportWebVitals';
require('dotenv').config()


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
