export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const SET_ITEMS_LENGTH = 'SET_ITEMS_LENGTH'
export const SET_CART_DATA = 'SET_CART_DATA'


export const setCartData = (cartData ={})=>({
    cartData,
    type : SET_CART_DATA
})

export const setItems = (items = [])=>({
    items,
    type : SET_ITEMS_LENGTH
})

export const addToCart = (item,count)=>({
    type : ADD_TO_CART,
    count,
    item
})


export const removeFromCart = (item,all)=>({
    type : REMOVE_FROM_CART,
    all,
    item
})