import React, { useEffect } from "react";
import { notifyTypes, notify, useMessage } from "../../hooks/useMessage";

export default function ErrorFallback() {
  useEffect(() => {
    // notify("ERROR.HAPPEND", notifyTypes.error);
    // window.location.reload();
  }, []);
  return <div></div>;
}
