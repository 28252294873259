import { notification } from "antd";
import { useSnackbar } from "notistack";
import { isAr } from "../utils/i18/getLang";
import { translate } from "../utils/i18/translate";

export const notifyTypes = {
  info: "info",
  success: "success",
  error: "error",
};

const defaultConfig = {
  anchorOrigin: {
    vertical: "top",
    horizontal: isAr() ? "right" : "left",
  },
  variant: "success",
};

export const notify = (message, type = "info", disabled) => {
  if (disabled) return;

  notification?.[type]({
    message: translate(message),
    placement: "topLeft",
    className: isAr() ? "rtl-font" : "ltr",
  });
};

export const useMessage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notify = (message, config = {}) => {
    const newConf = {
      ...defaultConfig,
      ...config,
    };
    enqueueSnackbar(translate(message), newConf);
  };
  return notify;
};
