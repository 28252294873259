export const ADD_COUNT = "ADD_COUNT";
export const EDIT_LANG = "EDIT_LANG";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_FILTERS = "SET_FILTERS";
export const SET_SUB_CATEGORIES = "SET_SUB_CATEGORIES";
export const SET_SHOW_SUB_HEADER = "SET_SHOW_SUB_HEADER";
export const SET_ORDER_DATA = "SET_ORDER_DATA";
export const SET_ReturnModal_DATA = "SET_ReturnModal_DATA";
export const SET_PRODUCT_DATA = "SET_PRODUCT_DATA";
export const SET_WISHLIST_ITEMS = "SET_WISHLIST_ITEMS";
export const SET_HOEM_MEDIA = "SET_HOEM_MEDIA";
export const REFRESH_CART = "REFRESH_CART";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_ORDER_STATUSES = "SET_ORDER_STATUSES";
export const SET_PHONE_CODE_DATA = "SET_PHONE_CODE_DATA";
export const SET_ENABLE_TAMARA = "SET_ENABLE_TAMARA";
export const SET_SHOW_PAYMENT_HINT = "SET_SHOW_PAYMENT_HINT";
export const SET_PRODUCT_OPTIONS = "SET_PRODUCT_OPTIONS";

export const setPhoneCodeData = ({ phone, code }) => ({
  type: SET_PHONE_CODE_DATA,
  data: {
    phone,
    code,
  },
});

export const setOrderStatuses = (statuses = []) => ({
  type: SET_ORDER_STATUSES,
  statuses,
});

export const setLangauges = (languages = []) => ({
  languages,
  type: SET_LANGUAGES,
});

export const refreshCart = () => ({
  type: REFRESH_CART,
});

export const setHomeMedia = (
  banners = [],
  videoBanners = [],
  slideImgs = []
) => ({
  type: SET_HOEM_MEDIA,
  media: {
    banners,
    videoBanners,
    slideImgs,
  },
});

export const setWishListItems = (items) => ({
  items,
  type: SET_WISHLIST_ITEMS,
});

export const showSubHeader = (show) => ({
  type: SET_SHOW_SUB_HEADER,
  show,
});

export const setFilters = (filters) => ({
  filters,
  type: SET_FILTERS,
});

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  categories,
});

export const setSubCategories = (subCategories) => ({
  type: SET_SUB_CATEGORIES,
  subCategories,
});

export const addCount = () => ({
  type: ADD_COUNT,
});

export const editLang = (lang = "en") => ({
  lang,
  type: EDIT_LANG,
});

export const toggleDrawer = (section, isOpen = true) => ({
  type: TOGGLE_DRAWER,
  section,
  isOpen,
});

export const setAuthToken = (token) => ({
  type: SET_AUTH_TOKEN,
  token,
});

export const setUserData = (userData) => ({
  userData,
  type: SET_USER_DATA,
});

export const setOrderData = (orderData) => ({
  orderData,
  type: SET_ORDER_DATA,
});

export const handleOpenReturnModal = (returnModal) => ({
  returnModal,
  type: SET_ReturnModal_DATA,
});

export const setClickedProduct = (product) => ({
  product,
  type: SET_PRODUCT_DATA,
});

export const setEnableTamara = (value) => ({
  value,
  type: SET_ENABLE_TAMARA,
});

export const changeShowHint = (value) => ({
  value,
  type: SET_SHOW_PAYMENT_HINT,
});

export const passProductOptions = (value) => ({
  value,
  type: SET_PRODUCT_OPTIONS,
});
