import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Router } from "./router";
import { setupAxios } from "./utils/axios/setup";
import { handleI18Class } from "./utils/layout/i18";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import I18Provider from "./HOC/I18Provider";

export default function App() {
  useEffect(() => {
    setupAxios();
  }, []);
  return (
    <div className={handleI18Class("container-fluid p-0 h-100")}>
      <SnackbarProvider maxSnack={3}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <I18Provider>
            <Provider store={store}>
              <Router />
            </Provider>
          </I18Provider>
        </ErrorBoundary>
      </SnackbarProvider>
    </div>
  );
}
