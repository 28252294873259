import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Loading } from "../components/Loading";
import PageViewTracker from "../components/PageViewTracker";
import ScrollToTop from "../components/scrollToTop";
import { changeShowHint } from "../redux/actions/shared";
import { handleI18Class } from "../utils/layout/i18";
import {
  Advertisment,
  AuthRouter,
  EmailPopup,
  Footer,
  Header,
  MainRouter,
} from "./routes";

export const Router = () => {
  const dispatch = useDispatch();
  var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  useEffect(() => {
    if (isSafari && iOS) {
      dispatch(changeShowHint(false));
    } else if (isSafari) {
      dispatch(changeShowHint(true));
    }
  }, []);
  return (
    <div className={handleI18Class("container-fluid p-0 h-100")}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <ScrollToTop>
            <Header />
            <Advertisment />
            <EmailPopup />
            <div className="header-nav" />
            <MainRouter />
            <AuthRouter />
            <PageViewTracker />
            {/* <MobileTabNav /> */}
            <Footer />
            {/* <ObserverRender /> */}
          </ScrollToTop>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};
