export const lang = localStorage.getItem('lang-dev') || 'en-gb'
export const arText = 'Ar'
export const enText = 'En'
export const en_lang_ID = 1;
export const ar_lang_ID = 2;


export const isAr = ()=>{
    const languageCode = getLangauage() || 'en-gb'
    return languageCode === 'ar'
} 


export const setLanguage = (newLang)=>{
    localStorage.setItem('language', JSON.stringify(newLang))
    window.location.reload()
}

const removePrevLangKey = ()=>{
    localStorage.removeItem('lang')
}

export const getLangauage = ()=>{
    removePrevLangKey()
    const storedLang = JSON.parse(localStorage.getItem('language'))
    return storedLang?.code || 'en-gb'
}

export const getLangauageData = ()=>{
    removePrevLangKey()
    const storedLang = JSON.parse(localStorage.getItem('language'))
    return storedLang
}