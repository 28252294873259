import { IntlProvider } from "react-intl";
import React from "react";
import { getLangauage } from "../../utils/i18/getLang";

import enMessages from "../../translate/en.json";
import arMessages from "../../translate/ar.json";

const allMessages = {
  en: enMessages,
  ar: arMessages,
};

export default function I18Provider(props) {
  const lang = getLangauage();
  const messages = allMessages[lang];

  return (
    <IntlProvider messages={messages} locale={lang}>
      {props.children}
    </IntlProvider>
  );
}
