import {
  ADD_COUNT,
  EDIT_LANG,
  SET_AUTH_TOKEN,
  SET_CATEGORIES,
  SET_FILTERS,
  SET_SHOW_SUB_HEADER,
  SET_SUB_CATEGORIES,
  SET_USER_DATA,
  TOGGLE_DRAWER,
  SET_ORDER_DATA,
  SET_ReturnModal_DATA,
  SET_PRODUCT_DATA,
  SET_WISHLIST_ITEMS,
  SET_HOEM_MEDIA,
  REFRESH_CART,
  SET_LANGUAGES,
  SET_ORDER_STATUSES,
  SET_PHONE_CODE_DATA,
  SET_ENABLE_TAMARA,
  SET_SHOW_PAYMENT_HINT,
  SET_PRODUCT_OPTIONS,
} from "../../actions/shared";
import { initSharedState } from "./initialState";

const sharedReducer = (state = initSharedState, action) => {
  switch (action.type) {
    case SET_PHONE_CODE_DATA:
      return {
        ...state,
        phoneCodeData: action.data,
      };

    case SET_ORDER_STATUSES:
      return {
        ...state,
        statuses: action.statuses,
      };

    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
      };
    case REFRESH_CART:
      return {
        ...state,
        cartRefreshCount: state.cartRefreshCount + 1,
      };

    case SET_HOEM_MEDIA:
      return {
        ...state,
        media: action.media,
      };
    case SET_WISHLIST_ITEMS:
      return {
        ...state,
        wishList: action.items,
      };
    case SET_SHOW_SUB_HEADER:
      return {
        ...state,
        subHeader: action.show,
      };
    case SET_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.subCategories,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
        categoriesLoaded: true,
      };
    case ADD_COUNT:
      return {
        ...state,
        refreshCounter: state.refreshCounter + 1,
      };
    case TOGGLE_DRAWER:
      return {
        ...state,
        drawer: {
          open: action.isOpen,
          content: action?.section || state.drawer.section,
        },
      };
    case EDIT_LANG:
      return {
        ...state,
        language: action.lang,
      };
    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.token,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    case SET_ORDER_DATA:
      return {
        ...state,
        orderData: action.orderData,
      };
    case SET_ReturnModal_DATA:
      return {
        ...state,
        returnModal: action.returnModal,
      };
    case SET_PRODUCT_DATA:
      return {
        ...state,
        product: action.product,
      };
    case SET_ENABLE_TAMARA:
      return {
        ...state,
        showTamara: action.value,
      };
    case SET_SHOW_PAYMENT_HINT:
      return {
        ...state,
        showPaymentHint: action.value,
      };
      case SET_PRODUCT_OPTIONS:
      const prevSelectedOptions = state?.selectedOptions;
      const filteredOptions = prevSelectedOptions?.filter(
        (item) => item?.product_id !== action.value?.product_id
      );
      const newOptions = [...filteredOptions, { ...action.value }];
      return {
        ...state,
        selectedOptions: newOptions,
      };
    default:
      return {
        ...state,
      };
  }
};

export default sharedReducer;
