export const initSharedState = {
  refreshCounter: 0,
  language: "en",
  drawer: false,
  authToken: null,
  userData: null,
  categories: [],
  filters: [],
  subCategories: [],
  subHeader: false,
  orderData: {},
  returnModal: false,
  product: {},
  wishList: [],
  media: null,
  cartRefreshCount: 0,
  languages: [],
  statuses: [],
  phoneCodeData: {},
  showTamara: true,
  currentPage: 1,
  showPaymentHint: true,
  selectedOptions: [],
};
