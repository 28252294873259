import Axios from 'axios'

export const setupAxios = (authToken = null)=>{

    Axios.interceptors.request.use(
        (config) => {
            config.headers.Authorization = `Basic c2hvcHBpbmdfb2F1dGhfY2xpZW50OnNob3BwaW5nX29hdXRoX3NlY3JldA==`;

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
                config.headers["Accept-Language"] = localStorage.getItem('lang-dev') || "en";
            }

            return config;
        },
        (err) => {
            Promise.reject(err);
        }
    );

    Axios.interceptors.response.use(
        function(response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            response.notification = {
                success:undefined,
                failure:undefined
            }
            if (response.data.status) {
                response.data.message = response.data.message || "Request Succeeded";
            }else{
                response.data.message = response.data.message || "Request Failed";
            }
            return response;
        },
        function(error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response)
                if ([401,403].includes(error.response.status) && !window.location.pathname.includes("auth/login") ) {
                    
                    // window.location.href = '/'
                    // localStorage.removeItem('userData')
                    // localStorage.setItem("auth-error", error.response.data.message || error.message)
                }
            return Promise.reject(error);
        }
    );
}