import React from "react";
import "./index.scss";

export default function LoadingAnimation() {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
