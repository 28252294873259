//interface item{
// id : string;
//size : string;
//count : number
// }


export const findInArray = (id , array = [])=>{
    return array.find(elem => elem?.product_id === id)
}

export const addElementToArray = (element , array = [],_quantity)=>{
    const found = findInArray(element?.product_id,array)
    if(found){
        const {quantity : prevCount} = found
        let newCount = _quantity ?  _quantity : +prevCount + 1 
        return array.map(elem => {
            if(elem.product_id === found.product_id)return({...elem,quantity : newCount})
            return(elem)
        })
    }
    const newElement = {
        ...element,
        quantity : 1
    }
    return [...array,newElement]
}

export const remvoeElementFromArray = (element , array = [],all = false)=>{
    const found = findInArray(element?.product_id,array)
    if(!found)
    return array

    const {quantity : prevCount} = found
    if(+prevCount === 1 || all)
    return array.filter(elem => elem.product_id !== element?.product_id)
    return array.map(elem => {
        if(elem.product_id === found.product_id)return({...elem,quantity : prevCount-1})
        return(elem)
    })
}