const dataLayer = window.dataLayer || [];

  export function gtag() {
      try {
    dataLayer?.push(arguments);
      } catch (error) {
          
      }
  }

export const dispatchGoogleEvent = (name,data) => {
    gtag("event", name , data);
  };