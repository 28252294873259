import { createStore, combineReducers , compose , applyMiddleware } from "redux";
import cartReducer from "./reducers/cart";
import sharedReducer from "./reducers/shared/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
  shared : sharedReducer,
  cart : cartReducer
});

const store = createStore(rootReducer,composeEnhancers(applyMiddleware()));
store.subscribe(() => {
  // console.log("store ", store.getState());
});

export default store;
