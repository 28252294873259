import React, { useEffect } from "react";
import { useLocation } from "react-router";

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  const homeClick = new KeyboardEvent("keyup", {
    bubbles: true,
    cancelable: true,
    keyCode: 36,
  });

  useEffect(() => {
    document.body.dispatchEvent(homeClick);
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
}
